import * as React from 'react'

import WpApi from './wpapi'
import Loader from './Loader'
import Loco from './Loco'
import QuotationStatus from  './QuotationStatus'
import FilteredChoices from './filtered_choices'
import Expander_helper from './Expander_helper'
import dateformat from './dateformat'

interface MyProps {
    tup:any,
    postid:number,
    is_simple_block:boolean,
    options:any,
    permission:any,
    level1:any,
    level2:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    supplier:string,
    supplier_choices: any,
    hideme:boolean,
    product:string,
    product_choices:any,
    is_loading:boolean,
    message:string,
    value:string
    
}
class QuotationStep extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.handle_select_supplier = this.handle_select_supplier.bind(this)
	this.handle_select_bouwdeel = this.handle_select_bouwdeel.bind(this)
	this.show_product_dropdown = this.show_product_dropdown.bind(this)
	this.handle_select_product = this.handle_select_product.bind(this)
	this.handle_cancel_click = this.handle_cancel_click.bind(this)
	this.state = {
	    supplier:'',
	    value:'',
	    message:'',
	    product:'',
	    hideme:false,
	    supplier_choices:{},
	    product_choices:{},
	    is_loading:false
	}
    }

    // pass-on the signal from QuotationStatus
    handle_cb(e:any,cb:(r:any)=>void){
	if(e.votetype==='choice-on-attr'){
	    WpApi.do_post('/hd-attr',e, (r:any) => {
		console.log('vote-ret')
		if(r.winner){
		    this.setState({value:r.winner})
		}
		cb(r)
	    })
	    return
	}
	if(e.cmd === "quotation-status-vote"){
	    console.log("cmd q-status-vote")
	    e.post_id = this.props.postid
	}
	this.props.cb_parent(e,(r:any)=>{
	    if(e.cmd === "quotation-status-vote"){
		console.log("return from q-status-vote")
		if(r.winner){
		    this.setState({value:r.winner})
		}
	    }
	    cb(r)
	})
	
    }
    handle_simplerow_click(row:any){
	console.log('clicked')
	let slug = row.name

	this.props.cb_parent({
	    cmd: 'set-quotation-status',
	    value: slug,
	    on_attr_id: this.props.tup.attr_id,
	    acf_key: this.props.options.quotation.key
	},(r:any)=>{
	    if(r.winner){
		console.log(`got  winner ${r.winner}`)
		this.setState({
		    value: r.winner,
		    is_loading:false
		})
	    }
	})
    }
    // hulplijst produkt
    handle_select_product(e:any){
	console.log('selected product')
	let slug = e.target.value
	console.log(`changed dropdown to: ${slug}`)
	console.log(`changed dropdown to: ${slug}`)
	let d = {
	    post_id : this.props.postid,
	    votetype: 'choice-on-attr',
	    value: slug,
	    aspect: 'huidig',
	    acf_key: this.props.options.produkt.key,
	    is_final_field: "false",
	    trigger_recalc: "false",
	    on_attr_id: this.props.tup.attr_id,
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})
	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.setState({
		is_loading:false,
		product:r.winner
	    })
	})
	
    }
    handle_cancel_click(){
	console.log('handle cancel')
	let d = {
	    action : 'delete-row',
	    attr_id: this.props.tup.attr_id
	}
	WpApi.do_post('/hd-repeaterbase',d, (r:any) => {
	    console.log(r)
	    if(r.status === 'ok'){
		this.setState({ hideme:true})
	    }else{
		this.setState({'message':r.message})
	    }
	});
    }
    // hulplijst supplier
    handle_select_supplier(e:any){
	console.log('selected supplier')
	let slug = e.target.value
	console.log(`changed dropdown to: ${slug}`)
	let d = {
	    post_id : this.props.postid,
	    votetype: 'choice-on-attr',
	    value: slug,
	    aspect: 'huidig',
	    acf_key: this.props.options.leverancier.key,
	    is_final_field: "false",
	    trigger_recalc: "false",
	    on_attr_id: this.props.tup.attr_id,
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})
	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.setState({
		is_loading:false,
		supplier:r.winner
	    })
	})
    }
    handle_select_bouwdeel(e:any){
	console.log('selected bouwdeel')
	let slug = e.target.value
	console.log(`changed dropdown to: ${slug}`)
	let d = {
	    post_id : this.props.postid,
	    votetype: 'choice-on-attr',
	    value: slug,
	    aspect: 'huidig',
	    acf_key: this.props.options.bouwdelen.key,
	    is_final_field: "false",
	    trigger_recalc: "false",
	    on_attr_id: this.props.tup.attr_id,
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})
	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.setState({
		is_loading:false,
		supplier:r.winner
	    })
	})
	
    }
    componentDidMount(){
	let atup = this.props.tup
	let filter = new FilteredChoices(
	    this.props.level1.name,
	    this.props.level2.name)


	this.setState({
	    supplier_choices: filter.filtered_choices(this.props.options.leverancier.buttons),
	    product_choices:  filter.filtered_choices(this.props.options.produkt.buttons),
	    value: atup.huidig.status,
	    product: atup.produkt,
	    supplier: atup.supplier

	})
    }
    is_expanded(){
	if(this.props.options.config !== undefined){
	    let conf = this.props.options.config
	    if(conf.attr === this.props.tup.attr_id){
		return true
	    }
	}
	let tup = this.props.tup

	if(tup.uistate !== undefined && tup.uistate === "new"){
	    return true
	}
	return false
    }
    render_bouwdeel_dropdown(){
	let choices = this.props.options.bouwdelen.choices
	return (

		<select style={{height:'unset'}} onChange={this.handle_select_bouwdeel}>
		<option value="">-kies-</option>

	    </select>

	)
	

    }
    show_product_dropdown() : boolean {
	let choices = this.state.product_choices
	let len = Object.entries(choices).length
	return (len > 0)
    }
    render_produkt_dropdown(){
	let choices = this.state.product_choices
	let product = this.state.product
	let has_status = this.state.value !== "kies"

	if(product && has_status){
	    return (
		<div className="label">{choices[product]}</div>
	    )
	}
	let product_value = product===null ? '' : product
	return (

		<select value={product_value} style={{height:'unset'}} onChange={this.handle_select_product}>
		<option value="">-kies-</option>
		{Object.entries(choices).map(([cname,clabel]:[string,any],i:number) => {
		    return (<option key={i} value={cname}>{clabel}</option>)
		})}
	    </select>

	)

    }
    get_supplier_label(){
	let choices = this.state.supplier_choices
	let supplier = this.state.supplier
	if(supplier){
	    return choices[supplier]
	}
	return 'Nog te bepalen'

    }
    render_supplier_dropdown(){
	let choices = this.state.supplier_choices
	let supplier = this.state.supplier
	let has_status = this.state.value !== "kies"
	let group_adm = false
	if(this.props.permission.bbgroup !== undefined){
	    let bbg = this.props.permission.bbgroup.gmember_level
	    if( bbg === 'admin' || bbg === 'mod'){
		group_adm = true
	    }
	}
		    
	let with_dropdown = supplier === null
	if(!has_status){
	    with_dropdown = true
	}
	if(group_adm){
	    with_dropdown = true
	}
	if(this.props.permission.user !== undefined){
	    let usr = this.props.permission.user
	    if(['zw-adviseur'].includes(usr.member_type )){
		with_dropdown = true
	    }
	}
	    
	if(! with_dropdown){
	    return (
		<div className="label">{this.get_supplier_label()}</div>
	    )
	}
	let supplier_value = supplier === null ? '' : supplier
	return (

		<select value={supplier_value} style={{height:'unset'}} onChange={this.handle_select_supplier}>
		<option value="">-kies-</option>
		{Object.entries(choices).map(([cname,clabel]:[string,any],i:number) => {
		    return (<option key={i} value={cname}>{clabel}</option>)

		})}
	    </select>

	)
    }
    render_simple_block_rows(){
	let all_rows = this.props.options.simple_options.buttons;
	let winner = this.state.value
	let rows = all_rows.filter((el:any)=>{
	    let logic = el.conditional_logic
	    var show = false
	    if(logic){
		for(var rule of logic){
		    for(var r of rule){
			if(winner === r.value){
			    show = true
			}
		    }
		}
	    }
	    return show
	})
	
	return rows.map((row:any,i:number) =>{
	    return (
		<div className="row" key={i}>
		    {row['cft-identifier'] === 'button'
		     ? (
			 <button onClick={(e)=>{this.handle_simplerow_click(row)}}>
			     {row.label}
			 </button>
		     )
		     : (
			 <div>{row.label}</div>
		     )
		    }
		    </div>

	    )
	})
    }
    render_simple_block(){
	let supplier = this.state.supplier
	if(supplier){
	    return (
		<div className="simple-block">
		    <div className="rows">
			{this.render_simple_block_rows()}
		    </div>
		</div>
	    )
	}
    }
    render_qstatus(asp:string,tup:any){
	return (
	    <QuotationStatus
	    label={this.get_label()}
	    aspect={asp}
	    acfkey={this.props.options.quotation.key}
	    permission={this.props.permission.quotation[asp]}
	    on_attr_id={this.props.tup.attr_id}
	    options={this.props.options.quotation[asp]}
	    postid={this.props.postid}
	    cb_parent={this.handle_cb}
	    tup={tup[asp]}
		/>
	)

    }
    get_label(){
	let supplier = this.get_supplier_label()
	let date = dateformat(new Date(this.props.tup.time))	
	return `Offerte voor: ${this.props.level2.label}; gericht aan: ${supplier} (aangemaakt op ${date})`
    }
    is_simple_block(){
	if(this.props.options.config !== undefined){
	    if(this.props.options.config.blocktype !== undefined){
		return this.props.options.config.blocktype === "simple"
	    }
	}
	return false

    }
    render(){
	if(this.state.hideme){
	    return null
	}
	let tup = this.props.tup
	/*
	let choices = this.props.level2.choices;
	let mtype = {
	    slug: tup.value,
	    label: choices[tup.value]
	}
	*/
	let mtype = ''

/*
		<div className="header-sub">
		    <div className="label">Betreft bouwdeel:</div>
			<div className="value">{this.render_bouwdeel_dropdown()} </div>
		    </div> 
		</div>
*/
	let exp = new Expander_helper();





	let label = this.get_label()
	let classes = ""
	
	if( this.is_expanded()){
	    classes += " highlight"
	}else{
	    classes += " collapsed"
	}
    	return (
	    <div className={"step quotation with-expand "+classes}>
		<div className="header" onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		    <div className="label">
		{label}
		    </div>
		</div>
		<div className="body">
		    <div className="aux-option">
		        <div className="label">Gericht aan welke Leverancier:</div>
			{this.render_supplier_dropdown()}
		    </div>
		    {this.show_product_dropdown() ? (
			<div className="aux-option">
			    <div className="label">Kies (merk-) produkt:</div>
			    {this.render_produkt_dropdown()} 
			</div>
		    ) : null}

	    {this.is_simple_block() ? this.render_simple_block() : (
			<div className="aspects default quotation-status two-columns">
			    {this.render_qstatus('huidig',tup)}
			    {this.render_qstatus('voorstel',tup)}
			</div>
		    )}
		{this.state.value === 'kies' ? (
		    <div className="buttons">
			<span className="cf-button" onClick={this.handle_cancel_click}>{Loco.tr('cancel_quotation')}</span>
			</div>
		) : null}
	    {this.state.message !== '' ? (<div className="cfmessage warning">{this.state.message}</div>):null}
		
	    </div>
	    </div>	    
		
	)
    }
}
export default QuotationStep;

