import * as React from 'react'
import HdaStep from './HdaStep';


interface MyProps {
    row:any
    options:any
    permissions:any
    cb_parent:   (e: any, cb: (r: any) => void) => void    
}
type MyState = {
    adviseur: string
    status:string
}
class AdviesAanvraag  extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.state = {
	    adviseur:'',
	    status:''
	}
    }
    // pass-on the signal from children
    handle_cb(e:any,cb:(r:any)=>void){
	console.log(e)
	if(e.cmd == "update-adviseur"){
	    this.setState({adviseur: e.value})
	}
	if(e.cmd == "set-section-status"){
	    if(e.step){
		this.setState({status:e.step})
		
	    }
	}
	this.props.cb_parent(e,(r:any)=>{
	    console.log(r)
	    if(r.winner_changed){
		this.setState({status:r.winner})
	    }
	    cb(r)
	})	
    }
    render_step(){
	let tup = this.props.row.tup
	let l2c = {
	    name: tup.level2
	}
	let l2tup = this.props.row.step_tup
	if(tup.level2 == 'NOT_LEVEL2'){
	    return (
		<HdaStep
		    options={this.props.options}
		    l2cluster={l2c}
		    cb_parent={this.handle_cb}
		    permission={this.props.permissions}
		    tup={l2tup} post_id={tup.post_id}
		/>
	    )
	}else{
	    let bdtup = {
		slug: tup.bouwdeel,
		huidig: {
		    row: this.props.row.attr,
		    comments: this.props.row.comments.huidig
		},
		adviseur: {
		    huidig: {
			row: l2tup.adviseur,
			comments:[]
		    }
		}
	    }
	    return (
		<HdaStep
		    options={this.props.options}
		    l2cluster={l2c}
		    cb_parent={this.handle_cb}
		    permission={this.props.permissions}
		    tup={l2tup} post_id={tup.post_id}
		    bptup={bdtup}
		/>
	    )
	}
	return null
    }
    render_expand(){
	let row = this.get_key_values()

	let fields = {
	    'Aangemaakt': row.date,
	    'Status':row.status,
	    'Toegewezen aan':row.adviseur,
	    'Adres' : row.adres,
	    'Maatregel-type': row.level1,
	    'Maatregel':row.level2,
	    'Postcode' : row.postcode,
	    'Plaats':row.plaats,
	    'Bouwdeel':row.bouwdeel,
	    'Aanvrager': row.contactname,
	    'Email':row.email,
	    'Tel':row.tel,
	    
	}
	
	return (
	    <div className="expand">
		<div className="fields panel">
		{Object.entries(fields).map(([fname,fvalue]:[string,any],i:number) => {
		    if(fvalue === '' || fvalue === null) { return null }
		    return (
			<div key={i} className="field">
			    <div className="xlabel">{fname}:</div>
			    <div className="value">{fvalue}</div>
			</div>
		    )
		})}
		</div>
		<div className="widgets">
		<div className="panel">
		{this.render_step()}
	        </div>
		</div>		
	    </div>
	)

    }
    row_click_handler(e:any){
	console.log('row-click')
	let qel = e.target.closest('.grid-row');

	let is_collapsed = qel.classList.contains('collapsed');
	
	console.log(' is collapsed ?  ' + (is_collapsed ? 'y' : 'n'))
	document.querySelectorAll('.aanvraag').forEach((x)=>x.classList.add('collapsed'))
	console.log(qel)
	if(is_collapsed){
	    qel.classList.remove('collapsed')
	}else{
	    qel.classList.add('collapsed')
	}
    }
    get_contact(){
	let row = this.props.row
	let con = row.hd.contact
	if(con == null){
	    return {
		first_name:'',
		last_name:'',
		mobiele_telefoon:'',
		email:''
	    }
	}
	return con
    }
    componentDidMount(){
	let row = this.props.row
	let v = row.step_tup.adviseur.value
	this.setState( {
	    adviseur: v,
	    status: row.tup.value
	})
    }
    get_adviseur_label(){
	let slug = this.state.adviseur
	let choices = this.props.options.adviseur.buttons_huidig.list
	let res = choices.filter((x:any) =>  x.name === slug)
	if(res.length === 1){
	    return res[0].label
	}
	return slug
    }

    get_status_value(){
	let row = this.props.row
	let v = this.state.status
	let choices = row.step_tup.huidig.choices
	return choices[v]
    }
    get_bouwdeel(){
	let tup = this.props.row.tup
	if (tup.bouwdeel === "") {
	    return ""
	}
	return this.props.options.buildingparts[tup.bouwdeel]
    }
    get_key_values(){
	let con = this.get_contact()
	let row = this.props.row
	let l2s = row.level1.level2_clusters
	let ret : {[key:string]:string} = {
	    level1: row.level1.label,
	    adres: row.hd.title,
	    date: row.date,
	    postcode: row.hd.postcode,
	    plaats: row.hd.plaats,
	    email: con.email,
	    tel: con.mobiele_telefoon,
	    contactname:  con.first_name + ' ' + con.last_name
	}
	
	ret.bouwdeel = this.get_bouwdeel()
	ret.adviseur = this.get_adviseur_label()
	ret.status = this.get_status_value()

	ret.level2 = row.tup.level2
	// lookup l2 label for l2 name
	for(var x of l2s){
	    if( x.name === row.tup.level2){
		ret.level2 = x.label
	    }
	}
	if(row.tup.level2 === 'NOT_LEVEL2'){
	    ret.level2 = ''
	}

	return ret
    }
    render(){
	let row = this.get_key_values()
	return (
	    <div  className="aanvraag grid-row collapsed">
		<div className="row" onClick={this.row_click_handler}>
		    <div className="pre-icon"></div>
		    <div className="date" title={row.date}>{row.date}</div>
		    <div className="adres" title={row.adres}>{row.adres}</div>
		    <div className="level1" title={row.level1}> {row.level1}</div>
		    <div className="level2" title={row.level2}> {row.level2}</div>
		    <div className="adviseur" title={row.adviseur}> {row.adviseur}</div>
		    <div className="status" title={row.status}> {row.status}</div>
		</div>
		{this.render_expand()}
	    </div>

	)
	
    }
}

export default AdviesAanvraag
