import * as React from 'react';
import ValueWithComments from "./ValueWithComments"
import Loco from "./Loco"


interface MyProps {
    cb_parent: (e:any, cb: (r: any) => void) => void,
    aspect:string,
    pattern:string,
    permission:any,
    label:string,
    select:any,
    tup:any,
    parent_label:string
}
type MyState = {
    is_loading:boolean,
    showall:boolean,
    values: {[aid:string]:any}
}

class CollabNumberInput extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)

	this.handle_input_click = this.handle_input_click.bind(this)
	this.handle_click_history = this.handle_click_history.bind(this)
	this.state = {
	    is_loading:false,
	    showall:false,
	    values:{}
	}
    }
    componentDidMount(){
	if(this.props.tup.values){
	    let vals = this.props.tup.values
	    this.setState({
		values: vals
	    })
	}
    }
    handle_click_history(){
	console.log('click history')
	this.props.cb_parent({
	    cmd: 'open-history',
	    acf_key: this.props.tup.key,
	    aspect: this.props.aspect,
	},()=>{})
    }
    
    handle_input_click(){
	console.log('input-click')
	let top3_ids = Object.entries(this.state.values).filter((x)=>x[1].top3).map((x)=>x[0])
	let winner_id =  Object.entries(this.state.values).filter((x)=>x[1].winner).map((x)=>x[0]).at(0)
	let lbl =   this.props.parent_label + ': ' +this.props.label
	var d :{[k:string]:any}= {
	    acfkey: this.props.tup.key,
	    top3: top3_ids,
	    label:lbl,
	    winner:winner_id,
	    aspect: this.props.aspect,
	    cmd: "number-vote",
	    trigger_recalc: true,
	    pattern: this.props.pattern
	}
	this.props.cb_parent(d,(r:any)=>{
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else{
		console.log(r)
		if(r.new_state){
		    this.setState({values:r.new_state, is_loading:false})
		}
	    }
	})
    }
    render_showall_btn(){
	let len = Object.entries(this.state.values).length

	if(len <= 3){
	    return null
	}
	return this.state.showall ? (
	    <span className="cf-button" onClick={()=>{this.setState({showall:false})}}>toon top 3</span>
	) : (
	    <span className="cf-button" onClick={()=>{this.setState({showall:true})}}>toon alle ({len})</span>
	)
    }
    render_v(attr_id:string,tup:any){


	

	let has_edit_perm = false;
	let has_view_perm = false;
	if(this.props.permission && this.props.permission.edit){
	    has_edit_perm = this.props.permission.edit.current_user;
	    has_view_perm = this.props.permission.view.current_user;
	}
	
	let lbl = this.props.parent_label + ': Opmerkingen over: ' +this.props.label
	
	if(this.state.showall || tup.top3){
	    return (
		<ValueWithComments key={attr_id} cb_parent={this.props.cb_parent}
		with_top3={true}
		pop_header={lbl}
		has_edit_perm={has_edit_perm}

		handle_input_click={this.handle_input_click}
		tup={tup}  />
	    )
	}
    }
    render(){
	let tup = this.props.tup
	//let prefix = this.props.label_prefix

	let has_edit_perm = false
	let label = this.props.label
	if(this.props.permission && this.props.permission.edit){
            has_edit_perm = this.props.permission.edit.current_user;
	}
	

	return (
	    <div className="collab-input-with-comment section-part aspect panel">
		<div className="header">
		    <div className="label">{label}
		    {this.render_showall_btn()} </div>
		    <span data-balloon="Toon historie"
			  data-balloon-pos="up" className="cf-icon-btn"
			  onClick={this.handle_click_history}><i className="bb-icon-l bb-icon-list"/></span>
		</div>
		<div className="body">
		<div className="collab-values">
		{Object.entries(this.state.values).map(([attr_id, tup]: [string, any]) => this.render_v(attr_id,tup))}
	    </div>
		{has_edit_perm ? (
		<div  className="buttons" onClick={this.handle_input_click}>
		    <span className="cf-button add-number">{Loco.tr('add_your_number')}</span>
			</div>
		) : null}
	    </div></div>
	)
    }
}

export default CollabNumberInput
