import * as React from 'react';
import WpApi from './wpapi'
import Loader from './Loader'
import HdaStep from './HdaStep';
import Loco from './Loco'
import Expander_helper from './Expander_helper'
import FilteredChoices from  './filtered_choices'

import Level2Cluster from './Level2Cluster'

interface MyProps {
    post_id:string,
    user:any,
    view:string,
    tup:any,
    permissions:any,
    options:any,
    cb_parent: (e: any, cb: (r: any) => void) => void
}
type MyState = {
    is_loading:boolean,

};

/*
 *  bd_remaining: filtered list of BouwDelen  ( building-parts) options, filtered for given section_name
 *    e.g. section_name 'isolatie_gevel'  ends with 'gevel' and filtered BD-options are:
 *      - gevel_bg
 *      - gevel_alle
 * 
 *  FIXME: for now, this only works for section 'isolatie'
 */

class Level2Clusters extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
    constructor(props: MyProps) {
        super(props)
	this.render_row = this.render_row.bind(this)
	this.state = {
	    is_loading:false,

	}

    }
    // row: buildingpart
    render_row(row:any){
	console.log(row)
    }


    public handle_cb(e:any, cb:(r:any)=>void){
    }
    render(){
	console.log(this.props.tup)

	let label_txt = this.props.tup.label
	let label_txt2 = this.props.tup.label
	let slab = this.props.tup.section_label
	//let m = /^([A-Z0-9]+)[ .]+(.*)/.exec(slab)
	let m = /^([^{]+){parent}/.exec(this.props.tup.label)

	
	if(m){
	    console.log('rematch')
	    console.log(m)
	    let lab1 = m[1].trim()
	    label_txt = (<span>{lab1}: <i>{slab}</i></span>)
	    label_txt2 = (<span>{lab1} <i>{slab}</i></span>)
	}
	

	//let m = /^([A-Z0-9]+)[ .]+(.*)/.exec(slab)
	    
	let exp = new Expander_helper();


	return (
	    <div className="level2-clusters">
		{this.state.is_loading ? (<Loader/>) : null}
	    {this.props.tup.level2_clusters.map((row:any,i:number) => 
		this.render_cluster(row,i))
	    }

	    </div>
	)
    }
    get_supplier_cond(supplier:string,level2:string){
	let list = this.props.options.repeater_blocks.leverancier.buttons
	let filt = new FilteredChoices(this.props.tup.name,level2)
	let choices = filt.filtered_choices(list)
	console.log('filtered: ')
	console.log(choices)
	return choices[supplier] !== undefined
    }
    render_cluster(row:any,i:number){
	let view = (this.props.tup.level2_clusters.length == 1)
	if(this.props.options.config.filter !== undefined){
	    let filt = this.props.options.config.filter
	    if(filt.supplier !== undefined){
		console.log(`filter by supplier ${filt.supplier}`)
		if(! this.get_supplier_cond(filt.supplier,row.name)){
		    return null
		}
	    }
	}
	return (
	    <Level2Cluster key={i} tup={row}
			   view={this.props.view}
			   options={this.props.options}
			   ptup={this.props.tup}
			   post_id={this.props.post_id}
			   cb_parent={this.props.cb_parent}
			   permissions={this.props.permissions}
			   compactview={view}
	    />
	)
    }
}
export default Level2Clusters
