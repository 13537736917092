import $ from 'jquery'

class GridHeader {
    click_col_header(e:any){
	if(e.target.nodeName == 'INPUT'){
	    return;
	}
	let trg = e.target.closest('.hdr-label')
	let name = trg.classList[0]
	console.log(` col-header clicked name: ${name}`)

	let cur = 'none'
	
	if(trg.classList.contains('sorting-d')){
	    cur = 'sorting-d'
	}else{
	    if(trg.classList.contains('sorting-a')){
		cur = 'sorting-a'
	    }
	}
	$('.listgrid > .header >div').removeClass('sorting-a');
	$('.listgrid > .header >div').removeClass('sorting-d');

	console.log(`current mode: ${cur}`)
	if(cur === 'sorting-a' || cur === 'none'){
	    console.log('set sorting-d')
	    trg.classList.add('sorting-d')
	    this.sort_rows(name,true)
	}else{
	    console.log('set sorting-a')
	    trg.classList.add('sorting-a')
	    this.sort_rows(name,false)
	}
    }
    sort_rows(name:string, rev:boolean){
	console.log(`sort_rows ${name}`)
	let body = '.listgrid > .body'
	//@ts-ignore
	$(body + ' > .grid-row').sort((a:HTMLElement,b:HTMLElement)=>{
	    let ta = $(a).find(`.row > .${name}`).text().toLowerCase();
            let tb = $(b).find(`.row > .${name}`).text().toLowerCase();
	    let ret = (ta > tb ? 1 : (ta < tb ? -1 : 0));
	    if(rev){
		return ret * -1;
	    }
	    return ret
	}).appendTo(body)
    }

    change_filter_input(e:any){
	let name = e.target.closest('div').classList[0];
	$('.grid-row > .row').removeClass('verberg')

	let filters : {[k:string]: string } = {}
	document.querySelectorAll('.listgrid > .header input').forEach((e)=>{
	    let k = $(e).attr('name')
	    if(k !== undefined){
		let v = String( $(e).val()).toLowerCase()
		if(v){
		    filters[k] = v
		}
	    }
	});
	console.log(filters)
	
	// iterate rows
	document.querySelectorAll('.grid-row > .row').forEach((e) => {
	    Object.entries(filters).map(([name,val]:[string,string]) =>{
		let child = e.querySelector(`.${name}`)
		if(child){
		    let text = child.textContent ? child.textContent : ''
		    console.log(` text ${text}`)
		    if(!text.toLowerCase().includes(val)){
			e.classList.add('verberg')
		    }
		}
	    })
	});
    }
    
}

export default GridHeader
