export function render_advise(a:advise_type,compactview:boolean){
    var txt = ''
    if(a.text !== undefined){
	txt = a.text
    }
    if(compactview){
	return txt
    }
    var price = a.price
    var gain =  a.gain
    if( gain === undefined || gain === '') {
	gain = '?'
    }
    if( price === undefined || price === ''){
	price = '?'
    }
    return  `${txt} (geschatte kosten: ${price} ; geschatte besparing: ${gain} )`
}

export type advise_type = {
    gain: string;
    price: string;
    text?:string; // FIXME: should not be optional, in the end
};


