import * as React from 'react'


import Loader from './Loader'
import Loco from './Loco'
import QuotationStep from './QuotationStep'
import Expander_helper from './Expander_helper'
import WpApi from './wpapi'


interface MyProps {
    postid:number,
    options:any,
    permission:any,
    l1cluster:any,
    expanded:boolean,
    l2cluster:any,
    bouwdeel:any,
    quotations:Array<any>,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    is_loading:boolean,
    quotations:Array<any>,
}

class QuotationsList extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)

	this.state = {
	    is_loading:false,
	    quotations:[]
	}
	
    }
    componentDidMount(){
	let quotations = this.props.quotations
	if(quotations.length > 0){
	    this.setState({'quotations': quotations})
	}
    }
    clicked_add_quotation(){
	let d = {
	    post_id: this.props.postid,
	    acf_key: this.props.l1cluster.key,
	    votetype: 'quotation',
	    level2: this.props.l2cluster.name,
	    bouwdeel: this.props.bouwdeel.slug,
	    value: this.props.l2cluster.name
	}
	this.setState({is_loading:true})

	WpApi.do_post('/hd-attr',d, (r) => {
	    r.status = 'kies'
	    r.uistate = 'new'
	    console.log(r);
	    this.setState(prevState => ({
		is_loading:false,
		quotations: [...prevState.quotations, r]
	    }))
	})

    }
    render_quotations(){
	return (
	    <div>
		{this.state.quotations.map((tup:any,i:number) => {
		    if(tup.level2 === this.props.l2cluster.name){
			//return (<div>YUP </div>)
			return <QuotationStep
				   key={i}
				   is_simple_block={false}
				   level2={this.props.l2cluster}
				   level1={this.props.l1cluster}
				   permission={this.props.permission}
				   options={this.props.options}
				   postid={this.props.postid}
				   cb_parent={this.props.cb_parent}
				   tup={tup}
			       />
		    }
		})}
	    </div>
	)
    }

    render(){
	let label_txt = (<span>Offertes voor: <i>{this.props.l2cluster.label}</i></span>)
	let exp = new Expander_helper()
	let collapsed = this.props.expanded ? "highlight" : "collapsed"
	return (
	    <div className={"quotations with-expand "+collapsed}>
		<div className="header" onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		<div className="label">{label_txt}</div>
		</div>
		<div className="body">
		    {this.render_quotations()}
		    {this.props.permission.hd_member_status.is_aspirantlid ?  (
			<span className="cf-button"
			      onClick={()=>this.clicked_add_quotation()}>{Loco.tr('add_quotation')}</span>
		    ) : (
			<div>
			    <span className="cf-button-disabled">{Loco.tr('add_quotation')}</span>
			    <div>(offertes kunnen pas worden aangevraagd na het zw-advies)</div>
			</div>
		    )}
		</div>
	    </div>
	)

    }
}
export default QuotationsList
