import * as React from 'react'


import Loader from './Loader'
import Loco from './Loco'
import ImplementationStep from './ImplementationStep'
import Expander_helper from './Expander_helper'
import WpApi from './wpapi'


interface MyProps {
    postid:number,
    options:any,
    expanded:boolean,
    context:string,
    permission:any,
    l1cluster:any,
    l2cluster:any,
    bouwdeel:any,
    implementations:Array<any>,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    is_loading:boolean,
    implementations:Array<any>,
}

class ImplementationsList extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)

	this.state = {
	    is_loading:false,
	    implementations:[]
	}
	
    }
    componentDidMount(){
	let implementations = this.props.implementations
	if(implementations.length > 0){
	    this.setState({'implementations': implementations})
	}
    }
    clicked_add_implementation(){
	let d = {
	    post_id: this.props.postid,
	    acf_key: this.props.l1cluster.key,
	    votetype: 'implementation',
	    level2: this.props.l2cluster.name,
	    bouwdeel: this.props.bouwdeel.slug,
	    value: this.props.l2cluster.name
	}
	this.setState({is_loading:true})

	WpApi.do_post('/hd-attr',d, (r) => {
	    r.status = 'kies'
	    r.uistate = 'new'
	    console.log(r);
	    this.setState(prevState => ({
		is_loading:false,
		implementations: [...prevState.implementations, r]
	    }))
	})

    }
    render_list(){
	return (
	    <div>
	    {this.state.implementations.map((tup:any,i:number) => {

		    //return (<div>YUP </div>)
		    return <ImplementationStep
		    key={i}
		    is_simple_block={false}
		    level2={this.props.l2cluster}
		    level1={this.props.l1cluster}
		    permission={this.props.permission}
		    options={this.props.options}
		    postid={this.props.postid}
		    cb_parent={this.props.cb_parent}
		    tup={tup}
			/>

	    })}
	    </div>
	)
    }
    render(){
	let label_txt = (<span>Uitvoering van: <i>{this.props.l2cluster.label}</i></span>)
	let exp = new Expander_helper()
	let collapsed = this.props.expanded ? "highlight" : "collapsed"
	
	return (
	    <div className={"implementations with-expand "+collapsed}>
		<div className="header" onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		    <div className="label">{label_txt}</div>
		</div>
		<div className="body">
		    {this.render_list()}
		    {this.props.context === "supplier-view" ? null : (
			<span className="cf-button"
			      onClick={()=>this.clicked_add_implementation()}>{Loco.tr('add_implementation')}</span>
		    )}
		</div>
	    </div>
	)

    }
}
export default ImplementationsList
