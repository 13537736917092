import * as React from 'react'
import Loco from './Loco'
import QuotationStep from './QuotationStep'

import HdaStep from './HdaStep';
import ImplementationList from './ImplementationList'
import Expander_helper from './Expander_helper'


interface MyProps {
    options:any,
    mode:string,
    permissions:any,
    row:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void    
}
type MyState = {
    supplier: string
    status_huidig:string,
    status_voorstel:string
}
class QuotationsAndCustomersRow extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.state = {
	    supplier:'',
	    status_huidig:'',
	    status_voorstel:''
	}
    }
    // pass-on the signal from children
    handle_cb(e:any,cb:(r:any)=>void){
	console.log(e)
	if(e.cmd == "update-supplier"){
	    this.setState({supplier: e.value})
	}
	if(e.cmd == "set-quotation-status"){
	    if(e.winner){
		if(e.aspect === 'huidig'){
		    this.setState({status_huidig:e.winner})
		}
		if(e.aspect === 'voorstel'){
		    this.setState({status_voorstel:e.winner})
		}
	    }
	}
	this.props.cb_parent(e,(r:any)=>{
	    console.log(r)
	    if(r.blocktype === "quotation-status"){
		if(r.aspect === "huidig"){
		    this.setState({status_huidig: r.winner})
		}
		if(e.aspect === 'voorstel'){
		    this.setState({status_voorstel:r.winner})
		}
	    }
	    cb(r)
	})	
    }
    
    row_click_handler(e:any){
	console.log('row-click')
	let qel = e.target.closest('.grid-row');

	let is_collapsed = qel.classList.contains('collapsed');
	
	console.log(' is collapsed ?  ' + (is_collapsed ? 'y' : 'n'))
	document.querySelectorAll('.quotation').forEach((x)=>x.classList.add('collapsed'))
	console.log(qel)
	if(is_collapsed){
	    qel.classList.remove('collapsed')
	}else{
	    qel.classList.add('collapsed')
	}
    }
    get_supplier_label(val:string){
	let options = this.props.options.leverancier.buttons
	let found = options.filter((x:any) => x.name === val)
	if(found.length === 1){
	    return found[0].label
	}
	return "not found"
    }
    componentDidMount(){
	let row = this.props.row
	this.setState({
	    supplier:row.supplier,
	    status_huidig: row.huidig.status,
	    status_voorstel: row.voorstel.status
	})
    }
    get_key_values(){
	let row = this.props.row
	let con = this.get_contact(row)

	return {
	    date: row.date,
	    supplier: this.get_supplier_label(this.state.supplier),
	    contact: con.first_name + ' ' + con.last_name,
	    adres: row.hd.title,
	    plaats: row.hd.plaats,
	    postcode: row.hd.postcode,
	    mobiel: con.mobiele_telefoon,
	    email: con.email,
	    level1: row.cluster.level1.label,
	    level2: row.cluster.level2.label,
	    status1: this.get_status_label(this.state.status_huidig,'huidig'),
	    status2: this.get_status_label(this.state.status_voorstel,'voorstel')
	}
    }
    
    render_expand(row:any){
	let val = this.get_key_values()

	
	let fields = {
	    'Aangemaakt': val.date,
	    'Leverancier': val.supplier,
	    'Aanvrager': val.contact,
	    'Adres' : val.adres,
	    'Plaats' : val.plaats,
	    'Postcode': val.postcode,
	    'Mobiel': val.mobiel,
	    'Email': val.email,
	    'Maatregel-type': val.level1,
	    'Maatregel': val.level2
	}
	let exp = new Expander_helper();
	
	return (
	    <div className="expand">
		<div className="fields panel">
		    {Object.entries(fields).map(([fname,fvalue]:[string,any],i:number) => {
			return (
			    <div key={i} className="field">
				<div className="xlabel">{fname}:</div>
				<div className="value">{fvalue}</div>
			    </div>)
		    })}

		</div>
		<div className="widgets">
		    <div className="qstatus panel">
			{this.render_qstep(row)}
		    </div>
		    <div className="implementation panel">
			{this.render_implementations(row)}
		    </div>
		</div>
	    </div>
	)
    }
    render_implementations(row:any){
	let opt = this.props.options
	let perm = this.props.permissions
	let actiestappen = {}
	/*
	return null
	*/
	return (
	    <ImplementationList
	    context={"supplier-view"}
	    expanded={false}
		postid={row.post_id}
		options={opt}
		permission={perm}
		l1cluster={row.cluster.level1}
		l2cluster={row.cluster.level2}
		bouwdeel={row.bouwdeel}
                implementations={row.implementations}
		cb_parent={this.props.cb_parent}
	    />
	)
    }
    render_qstep(row:any){
	return (
	    <QuotationStep
		is_simple_block={false}
		permission={this.props.permissions}
		level1={row.cluster.level1}
		level2={row.cluster.level2}
		options={this.props.options}
		postid={row.post_id}
		cb_parent={this.handle_cb}
		tup={row} />
	)
    }
    get_status_label(val:string, aspect:string){
	let choices = this.props.options.quotation[aspect].options.choices
	if(val !== null){
	    return choices[val];
	}
    }
    get_contact(row:any){
	let con = row.hd.contact
	if(con == null){
	    return {
		first_name:'',
		last_name:'',
		email:''
	    }
	}
	return con
    }
    render(){
	let row = this.props.row
	let cluster_l1 = row.cluster.level1.label
	let cluster_l2 = row.cluster.level2.label
	let supp = this.get_supplier_label(row.supplier)
	let con = this.get_contact(row)

	let suppliersmode = this.props.mode === "suppliers"
	let val = this.get_key_values()


	    return (
		<div  className=" grid-row collapsed">
		    <div className="row" onClick={this.row_click_handler}>
			<div className="pre-icon"></div>
			<div className="date">{val.date}</div>
			<div className="contact" title={val.contact}>{val.contact}</div>
			<div className="adres" title={val.adres}>{val.adres}</div>
			<div className="postcode">{val.postcode}</div>
			{suppliersmode? (
			    <div className="level1" title={val.level1}> {val.level1}</div>
			): null}
			<div className="level2"     title={val.level2}> {val.level2}</div>
			<div className="status1" title={val.status1}>{val.status1}</div>
			<div className="status2" title={val.status2}>{val.status2}</div>
			<div className="supplier" title={val.supplier}>{val.supplier}</div>

		    </div>
		    
		    {this.render_expand(row)}
		    
		</div>
	    )
    }
}

export default QuotationsAndCustomersRow
