class FilteredChoices{
    level1:string
    level2?:string
    
    constructor(l1:string,l2?:string){
	this.level1 = l1
	this.level2 = l2
    }

    filtered_choices(buttons:Array<any>){
	//console.log(`filtering "${this.level1}" > "${this.level2}" `)

	let ret :{[name:string]:string}= {};

	let btns = buttons.filter((el:any,i:number)=>{
	    //console.log(` .. btn ${el.name}`)
	    let logic = el.conditional_logic
	    var show = false
	    if(logic){
		for(var rule of logic){
		    for(var r of rule){
			//console.log(` ... logic ${r.value}`)
			let l1 = r.value
			let l2 = "*"
			if(r.value.includes(".")){
			    let parts = r.value.split('.')
			    l1 = parts[0]
			    l2 = parts[1]
			    //console.log(`split in l1: "${l1}" l2: ${l2}`)
			}
			if(l1 === this.level1){
			    //console.log('  .... L1 ok')
			    if( l2 === '*' || l2 === this.level2){
				show = true
			    }
			}
		    }
		}
	    }else{
		return true
	    }
	    return show
	})
	for(var b of btns){
	    ret[b.name] = b.label
	}
	return ret
    }
}

export default FilteredChoices;


